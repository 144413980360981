import { Key, useEffect, useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

import Container from "react-bootstrap/Container";
import styled from "styled-components";

import Colbs from "../images/colbs.gif";
import Background from "../images/hero.gif";
import Logo from "../images/logo.png";
import { getNFTsByOwner } from "../functions/staking";
import VaultItemAdvanced from "../components/VaultItemAdvanced";
import Tab from "react-bootstrap/esm/Tab";
import Tabs from "react-bootstrap/esm/Tabs";

const Factions = ({
  loading,
  setLoading,
}: {
  loading: boolean;
  setLoading: Function;
}) => {
  const { connection } = useConnection();
  const { publicKey } = useWallet();

  const [emptyBottles, setEmptyBottles] = useState(null);
  const [filledBottles, setFilledBottles] = useState(null);

  useEffect(() => {
    if (publicKey!) {
      refreshAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  async function refreshAll() {
    setLoading(true);
    await getUnstakedNfts();
    setLoading(false);
  }

  async function getUnstakedNfts() {
    if (publicKey) {
      const { emptyNfts, filledNfts } = await getNFTsByOwner(
        connection,
        publicKey
      );
      setEmptyBottles(emptyNfts);
      setFilledBottles(filledNfts);
    }
  }

  return (
    <>
      {loading && (
        <div
          style={{
            alignItems: "center",
            background: "rgba(35, 31, 61,0.75)",
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            position: "fixed",
            width: "100vw",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            zIndex: 10,
          }}
        >
          <img src={Colbs} alt="Loading" />
        </div>
      )}
      <PageWrapper>
        <HeaderWrapper>
          <Header>
            <a href="/">
              <img src={Logo} height={80} alt={"Zenjin Viperz"} />
            </a>
            <div style={{ display: "flex" }}>
              <HeroLinks>
                <HeroLink href="https://www.zenjinviperz.io">Home</HeroLink>
                <HeroLink href="https://factions.zenjinviperz.io">
                  Factions
                </HeroLink>
                <HeroLink href="https://missions.zenjinviperz.io">
                  Missions
                </HeroLink>
                <HeroLink href="#">Bottles</HeroLink>
                <HeroLink href="https://snek.zenjinviperz.io">
                  Whitepaper
                </HeroLink>
                <HeroLink
                  href="http://www.twitter.com/ZenjinViperz"
                  target="_blank"
                >
                  <svg
                    width="36"
                    height="36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#a)">
                      <path
                        d="M36 6.835a14.746 14.746 0 0 1-4.242 1.163 7.398 7.398 0 0 0 3.247-4.086 14.795 14.795 0 0 1-4.69 1.792 7.373 7.373 0 0 0-5.391-2.332c-4.768 0-8.273 4.449-7.195 9.067-6.137-.307-11.579-3.247-15.223-7.716-1.934 3.32-1.003 7.662 2.285 9.861a7.354 7.354 0 0 1-3.344-.924c-.08 3.422 2.372 6.623 5.924 7.335a7.403 7.403 0 0 1-3.336.126 7.391 7.391 0 0 0 6.9 5.129A14.85 14.85 0 0 1 0 29.31a20.909 20.909 0 0 0 11.322 3.318c13.713 0 21.46-11.582 20.993-21.97A15.036 15.036 0 0 0 36 6.836Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </HeroLink>
                <HeroLink
                  href="http://www.discord.gg/ZenjinViperz"
                  target="_blank"
                >
                  <svg
                    width="36"
                    height="36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#a)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M29.31 0A3.699 3.699 0 0 1 33 3.708V36l-3.87-3.42-2.178-2.016-2.304-2.142.954 3.33H5.19a3.699 3.699 0 0 1-3.69-3.708V3.708A3.699 3.699 0 0 1 5.19 0h24.12Zm-6.948 23.508c3.978-.126 5.508-2.736 5.508-2.736 0-5.796-2.592-10.494-2.592-10.494-2.592-1.944-5.058-1.89-5.058-1.89l-.252.288c3.06.936 4.482 2.286 4.482 2.286a14.673 14.673 0 0 0-5.418-1.728 15.23 15.23 0 0 0-3.636.036l-.306.036c-.63.054-2.16.288-4.086 1.134-.666.306-1.062.522-1.062.522s1.494-1.422 4.734-2.358l-.18-.216s-2.466-.054-5.058 1.89c0 0-2.592 4.698-2.592 10.494 0 0 1.512 2.61 5.49 2.736 0 0 .666-.81 1.206-1.494-2.286-.684-3.15-2.124-3.15-2.124l.504.306.072.054.07.04.021.01.071.04c.45.252.9.45 1.314.612.738.288 1.62.576 2.646.774a12.61 12.61 0 0 0 4.662.018c.846-.144 1.71-.396 2.61-.774a10.3 10.3 0 0 0 2.07-1.062s-.9 1.476-3.258 2.142c.54.684 1.188 1.458 1.188 1.458Zm-8.37-8.406c-1.026 0-1.836.9-1.836 1.998 0 1.098.828 1.998 1.836 1.998 1.026 0 1.836-.9 1.836-1.998.018-1.098-.81-1.998-1.836-1.998Zm6.57 0c-1.026 0-1.836.9-1.836 1.998 0 1.098.828 1.998 1.836 1.998 1.026 0 1.836-.9 1.836-1.998 0-1.098-.81-1.998-1.836-1.998Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </HeroLink>
              </HeroLinks>
              <WalletMultiButton />
            </div>
          </Header>
        </HeaderWrapper>
        <Hero>
          <Container>
            <VerticalGrid>
              <FactionIntro>
                <h2 className="h2">POTION OR POISON</h2>
                <p>
                  Zenjin alchemists first created the mysterious bottles to heal
                  Viperz in battle, but they quickly found out they had many
                  other uses.
                </p>
              </FactionIntro>
            </VerticalGrid>
          </Container>
          <HeroContainer>
            <HeroOverlay />
            <HeroBackground src={Background} alt="Background" />
          </HeroContainer>
        </Hero>
        {!publicKey ? (
          <WalletContainer>
            <WalletMultiButton />
          </WalletContainer>
        ) : (
          <Container>
            <VaultsContainer>
              <RefreshButton>
                <button className="refresh" onClick={() => refreshAll()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path
                      d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z"
                      fill="#fff"
                    ></path>
                  </svg>
                </button>
              </RefreshButton>
              <BuyButton>
                <a
                  href="https://magiceden.io/marketplace/zenjin_bottle"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Buy on Magic Eden
                </a>
              </BuyButton>
              <CustomTabs defaultActiveKey="filled" id="">
                <Tab
                  eventKey="filled"
                  title={`Filled (${filledBottles?.length ?? 0})`}
                >
                  {filledBottles && (
                    <VaultWrapper>
                      <VaultContainer>
                        {filledBottles?.map(
                          (nft: {
                            mint: { toString: () => Key | null | undefined };
                          }) => (
                            <VaultItemAdvanced
                              key={nft?.mint?.toString()}
                              nft={nft}
                            />
                          )
                        )}
                      </VaultContainer>
                    </VaultWrapper>
                  )}
                </Tab>
                <Tab
                  eventKey="empty"
                  title={`Empty (${emptyBottles?.length ?? 0})`}
                >
                  {emptyBottles && (
                    <VaultWrapper>
                      <VaultContainer>
                        {emptyBottles?.map(
                          (nft: {
                            mint: { toString: () => Key | null | undefined };
                          }) => (
                            <VaultItemAdvanced
                              key={nft?.mint?.toString()}
                              nft={nft}
                              refreshAll={refreshAll}
                              setLoading={setLoading}
                              type={"empty"}
                            />
                          )
                        )}
                      </VaultContainer>
                    </VaultWrapper>
                  )}
                </Tab>
              </CustomTabs>
            </VaultsContainer>
          </Container>
        )}
      </PageWrapper>
    </>
  );
};

export default Factions;

const CustomTabs = styled(Tabs)`
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  margin-bottom: 30px;
  padding: 10px 0px;
  padding-left: 36px;
  width: 100%;

  .nav-item {
    border: medium none;
    border-radius: 20px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    padding: 6px 0;
    text-transform: capitalize;
    transition: all 0.2s ease 0s;

    &.active {
      background: #eb4b44;
      color: #231f3d;
      padding: 6px 24px;
    }
  }
`;

const RefreshButton = styled.div`
  left: 0;
  position: absolute;
  top: 12px;

  button {
    background: transparent;
    border: 0;
    box-shadow: 0;
    padding: 0;
    transition: all 0.3s ease 0s;

    &:hover {
      transform: rotate(180deg);
    }
  }
`;

const BuyButton = styled.div`
  right: 0;
  position: absolute;
  top: 12px;

  a {
    color: #fff;
  }
`;

const PageWrapper = styled.div`
  margin-bottom: auto;
`;

const HeaderWrapper = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
`;

const HeroLinks = styled.div`
  align-items: center;
  display: flex;
  margin-right: 20px;
  position: relative;
  z-index: 5;

  @media only screen and (max-width: 768px) {
    a {
      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(2) {
        display: none;
      }
    }
  }
`;

const HeroLink = styled.a`
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

const WalletContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  z-index: 5;
`;

const Hero = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 150px;

  @media only screen and (min-width: 768px) {
    padding-bottom: 50px;
  }
`;

const VerticalGrid = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FactionIntro = styled.div`
  max-width: 590px;
  position: relative;
  text-align: center;
  z-index: 5;
`;

const HeroContainer = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const HeroOverlay = styled.div`
  background: rgba(35, 31, 61, 0.75);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`;

const HeroBackground = styled.img`
  display: block;
  margin: 0 auto;
  width: calc(282px * 3);

  @media only screen and (max-width: 768px) {
    position: relative;
    top: 10vh;
    width: 100%;
  }
`;

const VaultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

const VaultsContainer = styled.div`
  margin: 50px auto;
  min-height: 500px;
  position: relative;
  z-index: 5;
`;

const VaultContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    grid-gap: 46px;
    grid-template-columns: repeat(3, minmax(0px, 1fr));
    margin: 0;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }
`;
