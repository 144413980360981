import { gql } from "@apollo/client";

export const FillBottle = gql`
  mutation FillBottle($mintId: String!) {
    fillBottle(mintId: $mintId) {
      errors {
        message
        field
      }
      txSig
    }
  }
`;

export const EmptyBottle = gql`
  mutation EmptyBottle($mintId: String!) {
    emptyBottle(mintId: $mintId) {
      errors {
        message
        field
      }
      txSig
    }
  }
`;
