import { useMutation } from "@apollo/client";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { FillBottle } from "functions/queries";
import { checkStatus } from "functions/staking";
import { doTransfer } from "functions/transfer";
import toast from "react-hot-toast";
import styled from "styled-components";

const VaultItemAdvanced = ({
  nft,
  refreshAll,
  setLoading,
  type,
}: {
  nft: any;
  refreshAll?: Function;
  setLoading?: Function;
  type?: string;
}) => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const [fillBottle] = useMutation(FillBottle);

  const image = nft?.json ? nft?.json?.image : nft?.image;
  const name = nft?.json ? nft?.json?.name : nft?.name;

  const fillBottleAction = async () => {
    setLoading(true);
    const id = toast.loading("Filling bottle...");
    const res = await await doTransfer(
      connection,
      publicKey,
      20,
      sendTransaction
    );

    if (res?.err === null) {
      try {
        const { data: result } = await fillBottle({
          variables: {
            mintId: nft?.mint?.toString(),
          },
        });

        if (result?.fillBottle?.errors?.length) {
          setLoading(false);
          toast.error(result?.setRank?.errors[0].message, {
            id,
          });
        } else {
          const txStatus = await checkStatus(
            connection,
            result?.fillBottle?.txSig
          );

          if (txStatus.err !== null) {
            setLoading(false);
            toast.error(
              "Something went wrong, please open a ticket on Discord.",
              {
                id,
              }
            );
          } else {
            setLoading(false);
            await refreshAll();
            toast.success("Bottle filled.", { id });
          }
        }
      } catch (err) {
        setLoading(false);
        toast.error("Something went wrong, please open a ticket on Discord.", {
          id,
        });
      }
    } else {
      toast.error("Something went wrong.", { id });
      setLoading(false);
    }
  };
  return (
    <VaultItemContainer>
      <img src={image} alt={name} />
      <VaultItemDetailsContainer>
        <p>{type === "empty" ? "Empty Bottle" : "Filled Bottle"}</p>
      </VaultItemDetailsContainer>
      <VaultItemButtonsContainer>
        {type === "empty" ? (
          <button onClick={() => fillBottleAction()}>
            Fill Bottle $20 SNEK
          </button>
        ) : (
          <button disabled onClick={() => console.log()}>
            Use
          </button>
        )}
      </VaultItemButtonsContainer>
    </VaultItemContainer>
  );
};

export default VaultItemAdvanced;

const VaultItemContainer = styled.div`
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  display: flex;
  flex-direction: column;
  max-width: 240px;
  overflow: hidden;
  position: relative;

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
`;

const VaultItemDetailsContainer = styled.div`
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;

  p {
    color: #222;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
`;

const VaultItemButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;

  button {
    background: #eb4b44;
    border: 0;
    border-radius: 30px;
    box-shadow: none;
    color: #231f3d;
    padding: 6px 24px;

    &:disabled {
      background: #eee;
      color: #999;
    }
  }

  .progress {
    margin-left: 24px;
    margin-right: 24px;
    width: 100%;

    .progress-bar {
      background-color: #eb4b44 !important;
    }
  }
`;
