import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";
import { useCallback, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Factions from "./containers/Factions";

const App = () => {
  const [loading, setLoading] = useState(false);
  const network = WalletAdapterNetwork.Mainnet;

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet({ network }),
      getTorusWallet(),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [network]
  );

  const onError = useCallback((error) => {
    toast.error(
      error.message ? `${error.name}: ${error.message}` : error.name,
      {
        duration: 3000,
      }
    );
    setLoading(false);
  }, []);

  return (
    <ConnectionProvider endpoint="https://lively-twilight-grass.solana-mainnet.quiknode.pro/66488d8444b2f09a3768e819be103ad375eb2e7f/">
      <WalletProvider wallets={wallets} onError={onError}>
        <WalletModalProvider>
          <Factions loading={loading} setLoading={setLoading} />
        </WalletModalProvider>
        <Toaster position="top-center" reverseOrder={false} />
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
