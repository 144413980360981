import {
  Token,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { Connection, PublicKey, Transaction } from "@solana/web3.js";

/**
 * Makes a transaction of an SPL Token in behalf of `from` to `to`.
 *
 * @param conn     - solana web3 Connection object
 * @param from     - wallet to debit
 * @param amount   - quantity of the token to transfer
 * @param sendTransaction - function
 */
export const doTransfer = async (
  conn: Connection,
  from: PublicKey,
  amount: number,
  sendTransaction: Function
) => {
  const decimals = 9;
  const to = new PublicKey("ZNWddozGSf5enAN1Fw6eo12qH8aqsEEREWUfNdyNFTk");
  const token = new PublicKey("ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx");
  const source = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    token,
    from
  );

  // We verify that the source ATA exists and otherwise fail the operation
  // Since this account is the one that we will debit tokens from, there is no
  // point in creating it if it doesn't exist, since the balance would be 0 anyway.
  //
  // This doesn't actually verify that if the account exists, it's a valid ATA,
  // but shouldn't be too risky since the transaction will fail anyway if the
  // account is not valid.
  if ((await conn.getParsedAccountInfo(from)).value == null) {
    console.error("can't debit from an account that doesn't exist");
    return;
  }

  const dest = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    token,
    to
  );

  const txs = new Transaction();

  // Check if the destination ATA exists, otherwise we need to create it
  // in order to send tokens to it
  // this doesn't actually verify that if the account exists, it's a valid ATA,
  // but shouldn't be too risky since the transaction will fail anyway if the
  // account is not valid.
  if ((await conn.getParsedAccountInfo(dest)).value == null) {
    txs.add(
      Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        token,
        dest,
        to,
        from
      )
    );
  }

  txs.add(
    Token.createTransferCheckedInstruction(
      TOKEN_PROGRAM_ID,
      source,
      token,
      dest,
      from,
      [],
      amount * Math.pow(10, decimals),
      decimals
    )
  );

  try {
    let blockhashObj = await conn.getRecentBlockhash();
    txs.recentBlockhash = blockhashObj.blockhash;
    txs.feePayer = from!;

    const txid = await sendTransaction(txs, conn);

    let done = false;
    let status = {
      slot: 0,
      confirmations: 0,
      err: null,
    };
    // let subId = 0;

    status = await new Promise(async (resolve, reject) => {
      setTimeout(() => {
        if (done) {
          return;
        }
        done = true;
        console.log("Rejecting for timeout...");
        reject({ timeout: true });
      }, 120000);

      while (!done) {
        // eslint-disable-next-line no-loop-func
        (async () => {
          try {
            const signatureStatuses = await conn.getSignatureStatuses([txid]);
            status = signatureStatuses && signatureStatuses.value[0];
            if (!done) {
              if (!status) {
                console.log("REST null result for", txid, status);
              } else if (status.err) {
                console.log("REST error for", txid, status);
                done = true;
                reject(status.err);
              } else if (!status.confirmations) {
                console.log("REST no confirmations for", txid, status);
              } else {
                console.log("REST confirmation for", txid, status);
                done = true;
                resolve(status);
              }
            }
          } catch (e) {
            if (!done) {
              console.log("REST connection error: txid", txid, e);
            }
          }
        })();
        await sleep(2000);
      }
    });

    done = true;
    console.log("Returning status", status);
    return status;
  } catch (e) {
    console.log(e);
  }
};

const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
