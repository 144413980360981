import { programs } from "@metaplex/js";
import { Connection, PublicKey } from "@solana/web3.js";
import axios from "axios";

const {
  metadata: { Metadata },
} = programs;

const creatorId = process.env.REACT_APP_CREATOR_ID;

export async function getNFTsByOwner(conn: Connection, owner: PublicKey) {
  const emptyNfts = await getTokensByOwner(conn, owner);
  const filledNfts = await getFilledTokensByOwner(conn, owner);
  return { emptyNfts, filledNfts };
}

export async function getTokensByOwner(conn: Connection, owner: PublicKey) {
  const walletNfts = await Metadata.findDataByOwner(conn, owner);
  const nfts = [];
  for (let nft of walletNfts)
    if (
      nft?.data?.creators &&
      nft?.data?.creators[0]?.verified &&
      nft?.data?.creators[0]?.address === creatorId
    )
      nfts.push({
        mint: new PublicKey(nft.mint),
        data: nft.data,
        json: await fetch(nft.data.uri)
          .then((e) => e.json())
          .catch((e) => console.log(e)),
      });

  let collator = new Intl.Collator(undefined, { numeric: true });
  nfts.sort((a, b) => collator.compare(a.data.name, b.data.name));

  return nfts.filter(function (el) {
    const trait = el.json?.attributes?.find((item) => item.value !== "Potion");
    if (trait?.value !== "Filled") {
      return el;
    } else {
      return null;
    }
  });
}

export async function getFilledTokensByOwner(
  conn: Connection,
  owner: PublicKey
) {
  const walletNfts = await Metadata.findDataByOwner(conn, owner);

  const nfts = [];
  for (let nft of walletNfts)
    if (
      nft?.data?.creators &&
      nft?.data?.creators[0]?.verified &&
      nft?.data?.creators[0]?.address === creatorId
    )
      nfts.push({
        mint: new PublicKey(nft.mint),
        data: nft.data,
        json: await fetch(nft.data.uri)
          .then((e) => e.json())
          .catch((e) => console.log(e)),
      });

  let collator = new Intl.Collator(undefined, { numeric: true });
  nfts.sort((a, b) => collator.compare(a.data.name, b.data.name));

  return nfts.filter(function (el) {
    const trait = el.json?.attributes?.find((item) => item.value !== "Potion");
    if (trait?.value === "Filled") {
      console.log(0);
      return el;
    } else {
      console.log(1);
      return null;
    }
  });
}

export async function getNFTMetadataForMany(tokens, conn) {
  const promises = [];
  // let returnedNfts = []
  tokens.forEach((t) => promises.push(getNFTMetadata(t.mint, conn, t.pubkey)));
  const nfts = (await Promise.all(promises)).filter((n) => !!n);

  const filteredNfts = nfts?.filter((nft) => {
    if (
      nft?.onchainMetadata?.data?.creators &&
      nft?.onchainMetadata?.data?.creators[0]?.verified &&
      nft?.onchainMetadata?.data?.creators[0]?.address === creatorId
    ) {
      return nft;
    } else {
      return null;
    }
  });

  return filteredNfts;
}

async function getNFTMetadata(mint, conn, pubkey) {
  try {
    const metadataPDA = await Metadata.getPDA(mint);
    const onchainMetadata = (await Metadata.load(conn, metadataPDA)).data;
    const externalMetadata = (await axios.get(onchainMetadata.data.uri)).data;
    return {
      pubkey: pubkey ? new PublicKey(pubkey) : undefined,
      mint: new PublicKey(mint),
      onchainMetadata,
      externalMetadata,
    };
  } catch (e) {
    // console.log(`failed to pull metadata for token ${mint}`);
  }
}

export const checkStatus = async (connection, txid: string) => {
  let done = false;
  let status = {
    slot: 0,
    confirmations: 0,
    err: null,
  };

  try {
    status = await new Promise(async (resolve, reject) => {
      setTimeout(() => {
        if (done) {
          return;
        }
        done = true;
        console.log("Rejecting for timeout...");
        reject({ timeout: true });
      }, 120000);

      while (!done) {
        // eslint-disable-next-line no-loop-func
        (async () => {
          try {
            const signatureStatuses = await connection.getSignatureStatuses([
              txid,
            ]);
            status = signatureStatuses && signatureStatuses.value[0];
            if (!done) {
              if (!status) {
                console.log("REST null result for", txid, status);
              } else if (status.err) {
                console.log("REST error for", txid, status);
                done = true;
                reject(status.err);
              } else if (!status.confirmations) {
                console.log("REST no confirmations for", txid, status);
              } else {
                console.log("REST confirmation for", txid, status);
                done = true;
                resolve(status);
              }
            }
          } catch (e) {
            if (!done) {
              console.log("REST connection error: txid", txid, e);
            }
          }
        })();
        await sleep(2000);
      }
    });

    done = true;
    console.log("Returning status", status);
    return status;
  } catch (e) {
    return e;
  }
};

const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
